import { Controller } from "stimulus"
import pluralize from "pluralize"

export default class extends Controller {
  static targets = [
    "quantityInput", "minimumQuantityMessageContainer",
    "checkoutFlashContainer",
    "matchesCount", "matchesContainer", "showMoreMatchesLink",
    "productShowContainer", "productDescription", "varietyHeading", "addToCartButton", "readMoreLink", "minQuantityLink", "quantityCalculatedContainer", "quantityCalculatedText", "totalPrice", "productListingContainer", "productListingVarietyList",
    "addToCartForm", "cartQuantityInput"
  ]

  static values = {
    allMinimumQuantity: Number
  }

  connect() {
    this.enableScrollDetectionForCartButtons()
    this.autoExpandDescriptions()
    this.removeDescriptionUlMargins()

    this.setupQuantityInputListener()
    this.dispatchQuantityInputEventIfPresent()
  }

  // -----------------------------
  // Basic Setup / Utility
  // -----------------------------

  removeDescriptionUlMargins() {
    this.productDescriptionTarget.querySelectorAll("ul").forEach((ul) => {
      const prevP = ul.previousElementSibling
      if (prevP?.tagName === "P") {
        prevP.style.margin = "0"
      }
    })
  }

  dispatchQuantityInputEventIfPresent() {
    if (this.hasQuantityInputTarget) {
      this.quantityInputTarget.dispatchEvent(new Event("input"))
    }
  }

  enableScrollDetectionForCartButtons() {
    // If there's a .product-show-container, disable cart buttons while scrolling
    if (this.hasProductShowContainerTarget) {
      let isScrolling
      const handleScroll = () => {
        // Disable all cart buttons that aren't already disabled by other logic
        this.addToCartButtonTargets.forEach((button) => {
          // Store the previous disabled state if we haven't already
          if (!button.hasAttribute('data-prev-disabled')) {
            // Check both the attribute and the property since dynamic changes might only affect the property
            const wasDisabled = button.hasAttribute('disabled') || button.disabled
            button.setAttribute('data-prev-disabled', wasDisabled)
          }
          button.setAttribute('disabled', '')
          button.disabled = true
        })

        // Clear the existing timeout
        window.clearTimeout(isScrolling)

        // Set a timeout to run after scrolling ends (40ms)
        isScrolling = setTimeout(() => {
          // Re-enable only the buttons that weren't disabled before scrolling
          this.addToCartButtonTargets.forEach((button) => {
            const wasDisabled = button.getAttribute('data-prev-disabled') === 'true'
            if (!wasDisabled) {
              button.removeAttribute('disabled')
              button.disabled = false
            }
            button.removeAttribute('data-prev-disabled')
          })
        }, 40)
      }

      // Add scroll event listener
      window.addEventListener('scroll', handleScroll, false)
    }
  }

  onQuantityInputFocus() {
    if (this.hasQuantityInputTarget) {
      this.quantityInputTarget.value = ""
    }
  }

  onQuantityInputBlur(e) {
    if (this.hasQuantityInputTarget) {
      const allMinQuantity = Number(this.allMinimumQuantityValue)
      const userInputValue = e.target.value
      if (!userInputValue || userInputValue === "0") {
        this.quantityInputTarget.value = allMinQuantity
        this.quantityInputTarget.dispatchEvent(new Event("input"))
      }
    }
  }

  autoExpandDescriptions() {
    // Auto-click description '... More' if bigger than mobile
    if (window.innerWidth > 599) {
      this.readMoreLinkTargets.forEach((link) => {
        link.dispatchEvent(new Event("click", { bubbles: true, cancelable: true, view: window }))
      })
    }
  }

  onReadMoreLinkClick(e) {
    e.preventDefault()
    const content = e.target.dataset.fullContent
    e.target.parentElement.innerHTML = content
  }

  // -----------------------------
  // Quantity Input & Matches
  // -----------------------------

  setupQuantityInputListener() {
    if (!this.hasQuantityInputTarget) return

    // Handle "X lbs min" link
    this.minQuantityLinkTargets.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault()
          const linkQuantity = e.target.dataset.quantity
          if (this.hasQuantityInputTarget) {
            this.quantityInputTarget.value = linkQuantity
            this.quantityInputTarget.dispatchEvent(new Event("input"))
          }
      })
    })

    // Main input event
    this.quantityInputTarget.addEventListener("input", () => {
      this.onQuantityChange()
    })
  }

  onQuantityChange() {
    const newInputValue = Number(this.quantityInputTarget.value)
    const allOfferingsMinimumQuantity = Number(this.allMinimumQuantityValue)

    if (!newInputValue && this.hasMinimumQuantityMessageContainerTarget) {
      this.minimumQuantityMessageContainerTarget?.classList.remove("hidden")
      return
    }

    // Hide variety headings by default
    this.varietyHeadingTargets.forEach((heading) => {
      heading.classList.add('hidden')
    })

    // Check for minimum quantity
    if (newInputValue < allOfferingsMinimumQuantity) {
      // Show "no results" message
      if (this.hasMinimumQuantityMessageContainerTarget) {
        this.minimumQuantityMessageContainerTarget.classList.remove("hidden")
      }
      this.productListingContainerTargets.forEach((container) => {
        container.classList.add("hidden")
      })
      this._setMatchesCountTo(0)
      this._setShowMoreMatchesCountTo(
        this.productListingContainerTargets.length
      )
      this._showMoreMatchesLink()
      this._handleOnlyChildrenBorder()
    } else {
      // Hide "no results" message
      if (this.hasMinimumQuantityMessageContainerTarget) {
        this.minimumQuantityMessageContainerTarget.classList.add("hidden")
      }

      let matchingListingsCount = 0
      const allListings = this.productListingVarietyListTargets

      allListings.forEach((list) => {
        list.querySelectorAll("[data-product-show-target='productListingContainer']").forEach((container) => {
          const minimumQuantity = Number(container.dataset.minimumQuantity)
          if (newInputValue >= minimumQuantity) {
            this._makeListingVisible(container, newInputValue)
            // Show heading
            const heading = document.querySelector(
              `[data-product-show-target="varietyHeading"][data-variety="${list.dataset.variety}"]`
            )
            if (heading) heading.classList.remove("hidden")
            matchingListingsCount++
          } else {
            container.classList.add("hidden")
          }
        })
      })

      this._setMatchesCountTo(matchingListingsCount)
      const allListingsCount = this.productListingContainerTargets.length
      this._setShowMoreMatchesCountTo(allListingsCount - matchingListingsCount)
      if (allListingsCount - matchingListingsCount === 0) {
        this._hideMoreMatchesLink()
      } else {
        this._showMoreMatchesLink()
      }
      this._handleOnlyChildrenBorder()
    }
  }

  _makeListingVisible(elem, newInputValue) {
    // Reset states
    const quantityCalculatedText = elem.querySelector("[data-product-show-target='quantityCalculatedText']")
    const totalPriceElem = elem.querySelector("[data-product-show-target='totalPrice']")
    const cartButton = elem.querySelector("[data-product-show-target='addToCartButton']")
    const cartQuantityInput = elem.querySelector("[data-product-show-target='cartQuantityInput']")

    quantityCalculatedText.classList.remove("hidden")
    totalPriceElem.classList.remove("hidden")
    cartButton.classList.remove("hidden")
    elem.classList.remove("-less-than-min")
    elem.querySelector("[data-product-show-target='minQuantityLink']").classList.add("hidden")
    elem.classList.remove("hidden")

    /**
     * Calculate how many units.
     */
    const quantityCalculatedElem = elem.querySelector("[data-product-show-target='quantityCalculatedContainer']")
    const unit = quantityCalculatedElem.dataset.availableAs
    const maxQuantity = Number(cartButton.dataset.maxQuantity)
    const unitSize = Number(elem.dataset.unitSize)
    const maxQuantityAllowed = Math.min(newInputValue, maxQuantity)
    const remainder = maxQuantityAllowed % unitSize
    const toteOrBag = elem.dataset.toteOrBag === "true"
    const toteSize = Number(elem.dataset.toteSize)
    const bagSize = Number(elem.dataset.bagSize)

    let howMany = 0
    let actualQuantityToOrder = 0

    if (unit === "lb") {
      howMany = maxQuantityAllowed
      actualQuantityToOrder = maxQuantityAllowed
    } else {
      howMany = Math.floor(maxQuantityAllowed / unitSize)
      actualQuantityToOrder = maxQuantityAllowed - remainder
    }

    if (howMany === 0 && !toteOrBag) {
      howMany = Number((maxQuantityAllowed / unitSize).toFixed(2))
    }

    let howManyContent = ""
    if (toteOrBag) {
      const toteCount = Math.floor(maxQuantityAllowed / toteSize)
      const amountAfterTotes = maxQuantityAllowed - toteCount * toteSize
      const bagCount = Number((amountAfterTotes / bagSize).toFixed(2))

      // Check if unit is "lb" which means bulk purchasing is available
      const isBulkAvailable = unit === "lb"

      // For tote_or_bag products, calculate actual quantity differently
      if (isBulkAvailable) {
        // If bulk is available, use exact quantity
        actualQuantityToOrder = maxQuantityAllowed
      } else if (bagCount % 1 === 0) {
        // Whole number of bags
        actualQuantityToOrder = (toteCount * toteSize) + (bagCount * bagSize)
      } else {
        // For partial bags, need to round up to whole bags since bulk isn't available
        const wholeBagCount = Math.ceil(bagCount)
        actualQuantityToOrder = (toteCount * toteSize) + (wholeBagCount * bagSize)
      }

      // Display text calculations
      let bagDisplayText = bagCount
      if (!isBulkAvailable && bagCount % 1 !== 0) {
        // If not bulk and partial bags, display should show rounded up value
        bagDisplayText = `${bagCount} (rounds to ${Math.ceil(bagCount)})`
      }

      if (toteCount > 0 && bagCount > 0) {
        howManyContent = `${toteCount} ${pluralize("tote", toteCount)} & ${bagCount % 1 === 0 ? Math.floor(bagCount) : bagCount} ${pluralize(
          "bag",
          bagCount
        )}`
      } else if (toteCount > 0) {
        howManyContent = `${toteCount} ${pluralize("tote", toteCount)}`
      } else if (bagCount > 0) {
        howManyContent = `${bagCount % 1 === 0 ? Math.floor(bagCount) : bagCount} ${pluralize("bag", bagCount)}`

        // If we need to round up bags, add a note
        if (!isBulkAvailable && bagCount % 1 !== 0) {
          howManyContent += ` <span class="text-gray-500">(rounds to ${Math.ceil(bagCount)} ${pluralize("bag", Math.ceil(bagCount))})</span>`
        }
      }
    } else if (newInputValue !== maxQuantityAllowed) {
      howManyContent = `<span class="text-red-500 pr-1">${howMany % 1 === 0 ? Math.floor(howMany) : howMany.toFixed(2)} ${pluralize(unit, howMany)}</span>(max available)`
    } else if (unit.includes("lb") || remainder === 0) {
      howManyContent = `${howMany % 1 === 0 ? Math.floor(howMany) : howMany.toFixed(2)} ${pluralize(unit, howMany)}`
    } else {
      howManyContent = `<span class="text-red-500">${howMany % 1 === 0 ? Math.floor(howMany) : howMany.toFixed(2)} ${pluralize(
        unit,
        howMany
      )}</span>&nbsp;·&nbsp;${maxQuantityAllowed - remainder} ${pluralize(
        "lb",
        maxQuantityAllowed - remainder
      )}`
    }

    quantityCalculatedText.innerHTML = howManyContent

    // Use actualQuantityToOrder for cart quantity
    cartButton.dataset.quantity = actualQuantityToOrder.toString()
    if (cartQuantityInput) {
      cartQuantityInput.value = actualQuantityToOrder.toString()
    }

    // Update total price
    const pricePerUnit = Number(totalPriceElem.dataset.pricePerUnit)

    // For tote_or_bag, use the actualQuantityToOrder we calculated above
    const calculatedTotal = actualQuantityToOrder * pricePerUnit

    totalPriceElem.innerHTML = `$${calculatedTotal.toFixed(2).toLocaleString()}`
    if (calculatedTotal.toFixed(2).toLocaleString() === "0.00") {
      cartButton.setAttribute("disabled", "")
    } else {
      cartButton.removeAttribute("disabled")
    }
  }

  _handleOnlyChildrenBorder() {
    this.productListingContainerTargets.forEach((container) => {
      container.classList.remove("-only-child")
    })

    this.productListingVarietyListTargets.forEach((list) => {
      const visibleChildren = Array.from(list.children).filter(
        (child) => !child.classList.contains("hidden")
      )
      if (visibleChildren.length === 1) {
        visibleChildren[0].classList.add("-only-child")
      } else {
        visibleChildren.forEach((child) => child.classList.remove("-only-child"))
      }
    })
  }

  _setMatchesCountTo(count) {
    if (this.hasMatchesCountTarget) {
      this.matchesCountTarget.innerHTML = count
    }
  }

  _setShowMoreMatchesCountTo(count) {
    if (this.hasShowMoreMatchesLinkTarget) {
      this.showMoreMatchesLinkTarget.innerHTML = `Show ${count} more with higher minimum quantity`
    }
  }

  _showMoreMatchesLink() {
    if (this.hasShowMoreMatchesLinkTarget) {
      this.showMoreMatchesLinkTarget.classList.remove('hidden')
      if (this.hasMatchesContainerTarget) {
        this.matchesContainerTarget.classList.add('sticky', 'top-[85px]')
      }
    }
  }

  _hideMoreMatchesLink() {
    if (this.hasShowMoreMatchesLinkTarget) {
      this.showMoreMatchesLinkTarget.classList.add('hidden')
      if (this.hasMatchesContainerTarget) {
        this.matchesContainerTarget.classList.remove('sticky', 'top-[85px]')
      }
    }
  }

  onShowMoreMatchesClick(e) {
    e.preventDefault()
    this._hideMoreMatchesLink()

    // Update matches count
    const allListings = this.productListingContainerTargets
    this._setMatchesCountTo(allListings.length)

    // Show hidden matches + their state
    this.productListingContainerTargets.forEach((elem) => {
      if (!elem.classList.contains("hidden")) return

      // Hide quantity, total, and button (re-shown in partials)
      elem.querySelector("[data-product-show-target='quantityCalculatedText']").classList.add("hidden")
      elem.querySelector("[data-product-show-target='totalPrice']").classList.add("hidden")
      elem.querySelector("[data-product-show-target='addToCartButton']").classList.add("hidden")

      // Set bg color & show link for minimum quantity
      elem.classList.add("-less-than-min")
      elem.querySelector("[data-product-show-target='minQuantityLink']").classList.remove("hidden")

      // Finally unhide
      elem.classList.remove("hidden")
    })

    this.varietyHeadingTargets.forEach((heading) => {
      heading.classList.remove("hidden")
    })
  }

  // -----------------------------
  // Checkout Flash / Form Submission
  // -----------------------------

  showCheckoutFlash() {
    if (!this.hasCheckoutFlashContainerTarget) return
    this.checkoutFlashContainerTarget.classList.remove("opacity-0", "z-[-1000]")
    setTimeout(() => {
      this.checkoutFlashContainerTarget.classList.add("opacity-0", "z-[-1000]")
    }, 5000)
  }

  async onAddToCartButtonClick(e) {
    e.preventDefault()
    const button = e.currentTarget
    if (button.disabled) return
    button.setAttribute("disabled", "")
    const form = button.form

    const formData = new FormData(form)
    const data = {
      api_models_order_item: {
        quantity: formData.get("api_models_order_item[quantity]"),
        listing_id: formData.get("api_models_order_item[listing_id]")
      }
    }
    try {
      const response = await fetch(form.action, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(data)
      })

      const responseData = await response.json()

      if (response.ok) {
        form.reset()
        document.querySelector(".view-cart-button")?.classList.add("opacity-1")
        button.innerHTML = "Added to Cart"
        this.showCheckoutFlash()
        document.querySelectorAll(".cart-count").forEach((count) => {
          count.innerHTML = responseData.itemCount
        })
      } else {
        throw new Error(
          responseData.errors?.full_messages?.join(", ") ||
            responseData ||
            "Unknown error"
        )
      }
    } catch (error) {
      alert("There was an error adding this item to the cart.")
      if (window.Sentry) {
        window.Sentry.captureException("Error adding item to cart: " + error.message, {
          contexts: { data, errors: error }
        })
      }
    } finally {
      button.removeAttribute("disabled")
    }
  }
}